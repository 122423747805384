import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */
/* @jsx mdx */
import DefaultLayout from "C:/Users/felix/OneDrive/Documents/Bertram.Solutions/Website.TTcom/GatsbyJS-2022/src/components/mdx/component.js";
import { GenericPage } from "../../../components/mdx/page";
import { Breadcrumbs } from "../../../components/widgets/breadcrumbs";
import { Aside } from "../../../components/widgets/aside";
import { Border } from "../../../components/widgets/container";
import { ButtonPrimary, ButtonSecondary } from "../../../components/widgets/button";
import { MarketVane, MonthlyIndicatorsChart, DailyIndicatorsChart, PortfolioPerformanceChart } from "../../../components/widgets/market-vane";
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = GenericPage;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">





    <h1>{props.pageContext.frontmatter.title}</h1>
    <Breadcrumbs text="Articles" to="/articles/" mdxType="Breadcrumbs" />
    <Aside mdxType="Aside">
      <p><strong parentName="p">{`See Also`}</strong></p>
      <ul>
        <li parentName="ul"><a parentName="li" {...{
            "href": "/dashboard/market-vane/"
          }}>{`Market Vane signal`}</a></li>
      </ul>
    </Aside>
    <p>{`From a 30,000 ft perspective, tactical asset allocation evolves around a simple binary decision: risk-on versus risk-off. To make this decision, we need a market sentiment indicator that is reliable enough to avoid costly whipsaws yet fast enough to track sudden changes. In this article, we provide some insights into `}<em parentName="p">{`TuringTrader's Market Vane`}</em>{` indicator.`}</p>
    <div css={`height:0;clear:both;`} />
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "1200px"
        }
      }}>{`
      `}<a parentName="span" {...{
          "className": "gatsby-resp-image-link",
          "href": "/static/3c2734a4cfc22cf728a99a027262fbcd/c1b63/market-vane-1200x628.png",
          "style": {
            "display": "block"
          },
          "target": "_blank",
          "rel": "noopener"
        }}>{`
    `}<span parentName="a" {...{
            "className": "gatsby-resp-image-background-image",
            "style": {
              "paddingBottom": "52.33333333333333%",
              "position": "relative",
              "bottom": "0",
              "left": "0",
              "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAAKCAIAAAA7N+mxAAAACXBIWXMAAA7DAAAOwwHHb6hkAAAB0ElEQVQoz3WRy0sbURTG598Q3HTtX9C1ggvBlRVBqTNqSCKIuBC6kFKxPhcq+GjUmEnMSEYLAYkGaV2YxKBEFB+R+IqZJrk3MRMziejMmMncW8ZRS1EvH3dxOD++c75D4PffnaSMrUSsvy7TgvQzGC+pWhEh7d88ux/w8sSbmN5xJykfu9cnPGeRRB7eiBjjWLYYupLooEDZQa8nQ7xHJrPizin/xbHP+rm9i5sor1h8ORMDSRpQNGy1w+9r/8MIY302uViq+bbRwxyVfXZ/MKw2TJyQNCBtwOiEZga2u5KtDNe3DokXNxX9cz5KiPXjh02Wi3KDt6Jrs3LgoHY03LEMjS7OyHImV7xlMda3+uysgwVJDZyLQ96syQk7lzOfpiJVg6G66WPzEkfR0XYXMDOPzoupNgfsX+MJHUvlFTZU6FpKNczFm2x/KCfXvBAj7Ym6ycvq4bDBmaZoSNqANjwNKDtotCa/rmSInCxH+Qd2Nz/8O/Vj69oWSs9v89at3KxfoIOFES80z0cWdm5nfLm5gKDLGhAsPsG9f0sUUSldFGQkY6y+zhxk71l/DGtBotd30XYW1QcVIT0zFaFHPeXHF2R/OIMxVtSn4osQwn8Bszvzks6Fc1AAAAAASUVORK5CYII=')",
              "backgroundSize": "cover",
              "display": "block"
            }
          }}></span>{`
  `}<img parentName="a" {...{
            "className": "gatsby-resp-image-image",
            "alt": "Market Vane provides high-quality guidance for risk-on vs. risk-off decisions",
            "title": "Market Vane provides high-quality guidance for risk-on vs. risk-off decisions",
            "src": "/static/3c2734a4cfc22cf728a99a027262fbcd/c1b63/market-vane-1200x628.png",
            "srcSet": ["/static/3c2734a4cfc22cf728a99a027262fbcd/5a46d/market-vane-1200x628.png 300w", "/static/3c2734a4cfc22cf728a99a027262fbcd/0a47e/market-vane-1200x628.png 600w", "/static/3c2734a4cfc22cf728a99a027262fbcd/c1b63/market-vane-1200x628.png 1200w"],
            "sizes": "(max-width: 1200px) 100vw, 1200px",
            "style": {
              "width": "100%",
              "height": "100%",
              "margin": "0",
              "verticalAlign": "middle",
              "position": "absolute",
              "top": "0",
              "left": "0"
            },
            "loading": "lazy",
            "decoding": "async"
          }}></img>{`
  `}</a>{`
    `}</span></p>
    <h2>{`Goals`}</h2>
    <p>{`Let's start by defining an investment thesis:`}</p>
    <ul>
      <li parentName="ul">{`During bull markets, we believe that investments in U.S. stocks will lead to the highest returns.`}</li>
      <li parentName="ul">{`During bear markets, we believe that U.S. Treasuries will continue to have positive returns.`}</li>
      <li parentName="ul">{`Switching between bull and bear markets happens infrequently, but when it does, switching may be time-sensitive.`}</li>
    </ul>
    <p>{`Of course, this thesis is subject to personal preferences and opinions. In particular, readers might ask about our stance on ex-U.S. stocks, zero or negative bond yields, and a return to rising bond yields. However, we believe that this investment thesis will continue to be valid. Based on this thesis, we can set goals for our market sentiment indicator:`}</p>
    <ul>
      <li parentName="ul">{`Provide a binary output to guide a choice between investing in the S&P 500 and U.S. Treasuries with 10-Years to maturity.`}</li>
      <li parentName="ul">{`Make sure the indicator is filtered enough to make no more than five trading decisions per year. If possible, these trading decisions should occur on a regular monthly schedule.`}</li>
      <li parentName="ul">{`Ensure the indicator reacts fast enough to cope with high-volatility events, including the beginning of the 2008 recession and the 2020 COVID pandemic. If these catastrophic events happen, the indicator will most likely trigger outside of the monthly schedule.`}</li>
    </ul>
    <p>{`It is important to note that even if our indicator is designed to time a transition between investments in the S&P 500 and 10-Year U.S. Treasuries, this does not imply that we need to use it in conjunction with these assets. Instead, we believe our indicator works with any bull market strategy highly correlated to U.S. stocks. Similarly, `}<em parentName="p">{`Market Vane`}</em>{` should work with any bear market strategy that offers less volatility than the S&P 500 and positive returns while the S&P 500 is declining.`}</p>
    <h2>{`Stock Market Momentum`}</h2>
    <Aside mdxType="Aside">
      <p><strong parentName="p">{`Learn More`}</strong></p>
      <ul>
        <li parentName="ul"><a parentName="li" {...{
            "href": "/articles/momentum-mean-reversion/"
          }}>{`Momentum & Mean Reversion`}</a></li>
        <li parentName="ul"><a parentName="li" {...{
            "href": "/articles/low-noise-momentum/"
          }}>{`Low-Noise Momentum`}</a></li>
        <li parentName="ul"><a parentName="li" {...{
            "href": "https://alphaarchitect.com/2020/08/13/an-introduction-to-digital-signal-processing-for-trend-following/"
          }}>{`Signal Processing for Trend Following`}</a></li>
      </ul>
      <p><strong parentName="p">{`Related Portfolios`}</strong></p>
      <ul>
        <li parentName="ul"><a parentName="li" {...{
            "href": "/portfolios/tt-round-robin/"
          }}>{`TT's Round-Robin`}</a></li>
      </ul>
    </Aside>
    <p>{`Pretty much every investor has heard the saying `}<em parentName="p">{`The Trend is Your Friend`}</em>{`. Consequently, it comes as no surprise that trend-following is the starting point for our market-sentiment indicator. There are many different ways to measure momentum, raising the question of `}<em parentName="p">{`which`}</em>{` momentum to measure.`}</p>
    <p>{`The classic approach to this problem is to settle on a measurement `}<em parentName="p">{`method`}</em>{` and `}<em parentName="p">{`optimize`}</em>{` the parameters until we achieve the desired result. The parameter optimization especially poses a significant risk of `}<em parentName="p"><a parentName="em" {...{
          "href": "https://en.wikipedia.org/wiki/Overfitting"
        }}>{`overfitting`}</a></em>{`, which is why we wanted to reduce the amount of hard-coded parameters to the absolute minimum. One way to achieve this is by using `}<em parentName="p">{`walk-forward optimization`}</em>{`, which we used successfully with our `}<a parentName="p" {...{
        "href": "/portfolios/tt-round-robin/"
      }}>{`Round Robin`}</a>{` portfolio.`}</p>
    <p>{`For our `}<em parentName="p">{`Market Vane`}</em>{` indicator, we are expanding on this concept. We start with calculating a slow and a fast-moving average, similar to the `}<a parentName="p" {...{
        "href": "https://en.wikipedia.org/wiki/MACD"
      }}>{`MACD`}</a>{` indicator. The difference between the two moving averages, divided by the two's slower value, can be seen as a slope measure. Unfortunately, this measure depends on the time constants chosen for the moving averages. We can normalize this by dividing by the difference between the selected time constants.`}</p>
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "1177px"
        }
      }}>{`
      `}<a parentName="span" {...{
          "className": "gatsby-resp-image-link",
          "href": "/static/f01b494327a6dd241c3c8bfcce260a8e/c0ebd/stock-mom-swarm-correlation.png",
          "style": {
            "display": "block"
          },
          "target": "_blank",
          "rel": "noopener"
        }}>{`
    `}<span parentName="a" {...{
            "className": "gatsby-resp-image-background-image",
            "style": {
              "paddingBottom": "56.333333333333336%",
              "position": "relative",
              "bottom": "0",
              "left": "0",
              "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAALCAYAAAB/Ca1DAAAACXBIWXMAAA7DAAAOwwHHb6hkAAACNklEQVQoz32T7UsUURTG578N+hIEBb1BUUulROWirukHqShstnTdzBI3syVzy9YdTCsQTWtX92XedlbnfX5x7y4LQnjg4d4z95nznDP3GcVzXVzX5dh18X2fMAxptVpUq1Xq9ToHB4dyrdVqNBoNuRqGgR9EBEEgId7xPE9CsW0TXW/RaNRlYRFJknBaBFFC2zROcEXhKIpQbMvEMg10XSfw/T7pNDRqdYzaTo/bFfGDQDak6HqTZrOOaer4fkAcc0L5f93++vaRrcp7SKBj6TjtDm7nSHIVp+1g6C0M06RZ3UdbmZWySRxzbHfHisOQ0O1gWy2Zf3ozRjb7kMVCllK5yO/NVUoreaIElIV3L6isr2LZR+xvbzI6fpUHE4NslIvMZNPU/uyytpJnOHONu4PnZb78dpzbQ5e4cvMck5kUs88GuZ++3r0UbeML+bkpMkMppp8MMPLoFrnsJNNPB1gqqKhqms/FLBvfv7Lzo8IrdYzHE5cZHk2hFecp5EYoFqawmocEgY/iHXtolSIXbpzl3p2LOM2/ciytNEdbr1JefY3r2P3v91wdZTR1Br22K/Pdn2UO9zS5l7dsGCba2hI5Nc18IS8PwijCbh7gWCaOafbJImZy46jqZF/A1hu0qntEcYIvRrYsm+0tjfXSByrry8RxTNQzahBGEnHUNbGIl9kMi8sLXeFQWMWjc9Q1tfgxlEiSQwLfw2mbshMBUTju7QVRQPhU2MvptLvCvXNhF1FQPPsHTJslSDvaGGoAAAAASUVORK5CYII=')",
              "backgroundSize": "cover",
              "display": "block"
            }
          }}></span>{`
  `}<img parentName="a" {...{
            "className": "gatsby-resp-image-image",
            "alt": "TuringTrader's Market Vane, Stock-Market Momentum: Continuous tuning of filter parameters",
            "title": "TuringTrader's Market Vane, Stock-Market Momentum: Continuous tuning of filter parameters",
            "src": "/static/f01b494327a6dd241c3c8bfcce260a8e/c0ebd/stock-mom-swarm-correlation.png",
            "srcSet": ["/static/f01b494327a6dd241c3c8bfcce260a8e/5a46d/stock-mom-swarm-correlation.png 300w", "/static/f01b494327a6dd241c3c8bfcce260a8e/0a47e/stock-mom-swarm-correlation.png 600w", "/static/f01b494327a6dd241c3c8bfcce260a8e/c0ebd/stock-mom-swarm-correlation.png 1177w"],
            "sizes": "(max-width: 1177px) 100vw, 1177px",
            "style": {
              "width": "100%",
              "height": "100%",
              "margin": "0",
              "verticalAlign": "middle",
              "position": "absolute",
              "top": "0",
              "left": "0"
            },
            "loading": "lazy",
            "decoding": "async"
          }}></img>{`
  `}</a>{`
    `}</span></p>
    <p>{`In signal processing terms, the `}<em parentName="p">{`MACD is a band-pass filter`}</em>{`. By tuning its parameters, we can filter out high and low-frequency components and focus on those frequencies that best represent future returns. But instead of optimizing and setting these parameters once and forever, we go a different route. First, we create a swarm of MACD instances, each with a different set of parameters. We continually run this swarm of about 140 parameter sets in parallel, an easy task for a modern PC. Next, we calculate the correlation between today's realized return and yesterday's MACD value for every instance in the swarm. This correlation gives us an indication of the predictive value of each MACD instance.`}</p>
    <p>{`Finally, we select the instance with the highest correlation to forecast future stock market returns. As the chart above shows, the best filter settings continuously shift. Our optimization method for the highest correlation can quickly react to changing market conditions and select new filter parameters for us.`}</p>
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "1200px"
        }
      }}>{`
      `}<a parentName="span" {...{
          "className": "gatsby-resp-image-link",
          "href": "/static/fd63b71a0ed4a3a3b3844ac8997f5d60/21b4d/stock-mom-cumulative-returns.png",
          "style": {
            "display": "block"
          },
          "target": "_blank",
          "rel": "noopener"
        }}>{`
    `}<span parentName="a" {...{
            "className": "gatsby-resp-image-background-image",
            "style": {
              "paddingBottom": "56.333333333333336%",
              "position": "relative",
              "bottom": "0",
              "left": "0",
              "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAALCAYAAAB/Ca1DAAAACXBIWXMAAA7DAAAOwwHHb6hkAAACb0lEQVQoz3WT30sUURTH51/qMS2VSKQfD+JDED3UWvmrXaPQKBAifKktRV+SIIICqYcICqLWTc3NHwS6Oyuu4o9N98essz/dZubemdm9c76xU0oWfuHLPffyOYd7uOdKxdIeZbNZKpVKZBgG2bZNQgg4joOaHIcgBLkrEfblRjVOyTF6FUji/osY/dQ5SUJU8a8cInKc3/6TfOCEymgqnMfI2zh1DkXQNrBA9R0BXBqYIoOZJFUqFRekw0UObrGRZngXUjD4chV9Yys4f28Bdd1TaOwJotn7GWduTVLNVwbnkC+W3YJupvNXP3IsjbHXYYyOy2i98xWNPRM47Z3AWd9HtN4OoK0/iHP9IbT0zaKlb44ab87SxcFF5It7tZbFQaF8ycDoeAQNXQEcuzqJuuufcMobRFPvDE72fkOzL4gm3zTqb4RwwhvCSe8MGnwzdLx7Gm0D82RwGxJjHIsxFc/fr+Pu0wguPFiC58kKukZW0D0cRbs/gmv+MNr9MrqGl9HxOIzOoSg8fhkefxSeRxG6/FBG/7NVMphFUi6dQiKews6OioySQ1lVkN2OY3V5A9/Xytje5VhPalCSGczLGchxA9FYCoXMLrLJFEq5AikJBfHNBNm2RRLLpUloBaoynSpMI6HlyVJ/0ObSIn0JrZGmccrsJMks7NKbDzLJ8hZtRKIuaxYyJLhOmpomdWsdhq6TJBzCkdIVQM/uPzjAC4CuHgETSntlkjjnZFkWmabp2o05J25axJlB3NCIcZNMyyZWVIkzRqZdOcybJjHG3U8hOY5DomYhXDv7cbXqzqQQjpvknrn7GiP+54VwZ/kXdMYEFyEatp4AAAAASUVORK5CYII=')",
              "backgroundSize": "cover",
              "display": "block"
            }
          }}></span>{`
  `}<img parentName="a" {...{
            "className": "gatsby-resp-image-image",
            "alt": "TuringTrader's Market Vane, Stock-Market Momentum: Cumulative Returns",
            "title": "TuringTrader's Market Vane, Stock-Market Momentum: Cumulative Returns",
            "src": "/static/fd63b71a0ed4a3a3b3844ac8997f5d60/c1b63/stock-mom-cumulative-returns.png",
            "srcSet": ["/static/fd63b71a0ed4a3a3b3844ac8997f5d60/5a46d/stock-mom-cumulative-returns.png 300w", "/static/fd63b71a0ed4a3a3b3844ac8997f5d60/0a47e/stock-mom-cumulative-returns.png 600w", "/static/fd63b71a0ed4a3a3b3844ac8997f5d60/c1b63/stock-mom-cumulative-returns.png 1200w", "/static/fd63b71a0ed4a3a3b3844ac8997f5d60/21b4d/stock-mom-cumulative-returns.png 1280w"],
            "sizes": "(max-width: 1200px) 100vw, 1200px",
            "style": {
              "width": "100%",
              "height": "100%",
              "margin": "0",
              "verticalAlign": "middle",
              "position": "absolute",
              "top": "0",
              "left": "0"
            },
            "loading": "lazy",
            "decoding": "async"
          }}></img>{`
  `}</a>{`
    `}</span></p>
    <p>{`To test this method ran backtests starting from January 1990 with encouraging results. As the chart above shows, our stock-market momentum indicator avoided the worst of the recessions of 2000 and 2008. Further, our momentum indicator reacted swiftly enough to prevent whipsaws during short-term events, notably the pullback in December 2018 and the pandemic in March 2020.`}</p>
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "1200px"
        }
      }}>{`
      `}<a parentName="span" {...{
          "className": "gatsby-resp-image-link",
          "href": "/static/a8a174e3acded9934f75756914effab6/21b4d/stock-mom-rolling-returns.png",
          "style": {
            "display": "block"
          },
          "target": "_blank",
          "rel": "noopener"
        }}>{`
    `}<span parentName="a" {...{
            "className": "gatsby-resp-image-background-image",
            "style": {
              "paddingBottom": "56.333333333333336%",
              "position": "relative",
              "bottom": "0",
              "left": "0",
              "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAALCAYAAAB/Ca1DAAAACXBIWXMAAA7DAAAOwwHHb6hkAAABvUlEQVQoz41S227TQBD11yE+Bqk/whuvfasQKhdVAlGBGgWHqlAUqKACyaFtEnJpQ01tx85evXsGjR0nLWoljnS8Z+emmfEGaZqiSGZQssA8y/Djex9R9Isa/LkqyHlUWqiSTvqzpacEUWWvP95BSolgnkt6E/ZofJ7Sq86A7m206P6DXYQfzxCNJTYefkDv9ALdbyP0hhkePT7C4HTEfjqOZvT5eILNZ1/x/vCEfscJgiwr8LQ1xNuDITpfptjam2C7PaGdd3082Y1oe++Mwu6EWocjehn26UV7QPtHU3reOaf2pxGF3TG2Xv/EfndIcXyFwLmS7kJ6MeXRVvfLy5TImX/D0Iyd5zkCay15gLyvyftyDgTUe6s0+3hjVpE1ltjVxHsPcL5zDlprBGVZd1AVwLoQVnpt09aT1I6UcWRL39jBWigLKQV3WN4ocL3gaqalNtavYlhr4/iENg7aOkqSpOoQdc5NsM05QGoHZWpycuO7nlPfQXEcrzu8DTya96D/gfeehBD8DhfIFwrzQiAvJIqlznKBhVDVojmQT6UUtFIwxlR3ZqP5UbMOvPdg8l9i3qY5obHdFcNk/AVKOErhGhoP1gAAAABJRU5ErkJggg==')",
              "backgroundSize": "cover",
              "display": "block"
            }
          }}></span>{`
  `}<img parentName="a" {...{
            "className": "gatsby-resp-image-image",
            "alt": "TuringTrader's Market Vane, Stock-market momentum: Rolling Returns",
            "title": "TuringTrader's Market Vane, Stock-market momentum: Rolling Returns",
            "src": "/static/a8a174e3acded9934f75756914effab6/c1b63/stock-mom-rolling-returns.png",
            "srcSet": ["/static/a8a174e3acded9934f75756914effab6/5a46d/stock-mom-rolling-returns.png 300w", "/static/a8a174e3acded9934f75756914effab6/0a47e/stock-mom-rolling-returns.png 600w", "/static/a8a174e3acded9934f75756914effab6/c1b63/stock-mom-rolling-returns.png 1200w", "/static/a8a174e3acded9934f75756914effab6/21b4d/stock-mom-rolling-returns.png 1280w"],
            "sizes": "(max-width: 1200px) 100vw, 1200px",
            "style": {
              "width": "100%",
              "height": "100%",
              "margin": "0",
              "verticalAlign": "middle",
              "position": "absolute",
              "top": "0",
              "left": "0"
            },
            "loading": "lazy",
            "decoding": "async"
          }}></img>{`
  `}</a>{`
    `}</span></p>
    <p>{`While the results are much better than those of many simplistic trend-following strategies, there is still more to be desired. The rolling returns reveal that we've been able to keep up with the bull markets in the 1990s. However, we struggled to keep up with the benchmark between 2009 and 2014. We can improve this by adding more components to our indicator.`}</p>
    <h2>{`Economic Momentum`}</h2>
    <p>{`While the relationship between the economy and the stock market is complicated and often emotional, the two must ultimately be linked. Therefore, we firmly believe that adding a macro-economic indicator to our arsenal improves the quality of our trading decisions. There are many economic indicators to choose from, but, unfortunately, most of them have a monthly publication schedule and a significant publication lag. We are looking for a metric with a weekly publication schedule to induce as little lag as possible.`}</p>
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "1165px"
        }
      }}>{`
      `}<a parentName="span" {...{
          "className": "gatsby-resp-image-link",
          "href": "/static/9630062b80ae453989e878604c004b2e/96220/eco-mom-triggers.png",
          "style": {
            "display": "block"
          },
          "target": "_blank",
          "rel": "noopener"
        }}>{`
    `}<span parentName="a" {...{
            "className": "gatsby-resp-image-background-image",
            "style": {
              "paddingBottom": "30.333333333333336%",
              "position": "relative",
              "bottom": "0",
              "left": "0",
              "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAAGCAIAAABM9SnKAAAACXBIWXMAAA7DAAAOwwHHb6hkAAABFElEQVQY003M2U7CQACF4b7/+3jjldGEYC3KptBOp7N0hu7T2QtihZRoQvLl3P0neHh8ep4v8lqmlANKE0IQ54e25VXNqpqUFf1f3rT3WN0FvLOo6Mbxcj731gHnkfOwl4kgH4JEMJtjHGIcNu3WutTaP86CXpEA5HVMip/xcjq1xsTOpkolok9MuRZseSg3jK+a5tM6aC1wLnX3ccpqyJtb3BkTW58JA53PhiM6nrCbGigVEH0iZCIV0Ga68A5IRQJUiBgfxt/L+buzPtHFSoNZwSLOo5yGWu8Hnw4DPA5w8FAboPREGyAkCUglcdmzsssoAmQHNgv89rLevK62YbScfe3fKY8J29/ElE8wi1G+Qyy7AknFQ9NDaSkhAAAAAElFTkSuQmCC')",
              "backgroundSize": "cover",
              "display": "block"
            }
          }}></span>{`
  `}<img parentName="a" {...{
            "className": "gatsby-resp-image-image",
            "alt": "TuringTrader's Market Vane, Economic Momentum: Trigger Levels",
            "title": "TuringTrader's Market Vane, Economic Momentum: Trigger Levels",
            "src": "/static/9630062b80ae453989e878604c004b2e/96220/eco-mom-triggers.png",
            "srcSet": ["/static/9630062b80ae453989e878604c004b2e/5a46d/eco-mom-triggers.png 300w", "/static/9630062b80ae453989e878604c004b2e/0a47e/eco-mom-triggers.png 600w", "/static/9630062b80ae453989e878604c004b2e/96220/eco-mom-triggers.png 1165w"],
            "sizes": "(max-width: 1165px) 100vw, 1165px",
            "style": {
              "width": "100%",
              "height": "100%",
              "margin": "0",
              "verticalAlign": "middle",
              "position": "absolute",
              "top": "0",
              "left": "0"
            },
            "loading": "lazy",
            "decoding": "async"
          }}></img>{`
  `}</a>{`
    `}</span></p>
    <p>{`Following `}<a parentName="p" {...{
        "href": "https://fred.stlouisfed.org/series/SAHMCURRENT"
      }}>{`Sahm Rule Recession Indicator`}</a>{`'s idea, we decided to go with the `}<a parentName="p" {...{
        "href": "https://fred.stlouisfed.org/series/CCSA"
      }}>{`Continued Unemployment Claims`}</a>{`. Continued Claims were a little less noisy than `}<a parentName="p" {...{
        "href": "https://fred.stlouisfed.org/series/ICSA"
      }}>{`Initial Claims`}</a>{` in our testing, making them slightly better suited for our purposes.`}</p>
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "1200px"
        }
      }}>{`
      `}<a parentName="span" {...{
          "className": "gatsby-resp-image-link",
          "href": "/static/6cfe937d53d32de7ed089c7b118cd2bc/21b4d/eco-mom-cumulative-returns.png",
          "style": {
            "display": "block"
          },
          "target": "_blank",
          "rel": "noopener"
        }}>{`
    `}<span parentName="a" {...{
            "className": "gatsby-resp-image-background-image",
            "style": {
              "paddingBottom": "56.333333333333336%",
              "position": "relative",
              "bottom": "0",
              "left": "0",
              "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAALCAYAAAB/Ca1DAAAACXBIWXMAAA7DAAAOwwHHb6hkAAACaklEQVQoz3VTW0tUURg9P6nHkLwUJSRCIb0UQYjhSF5KUhHClwqCvEQEgb1Zrz30UBTeb0n3cXQMS8fJzpy5npkzOmfvM5d91opzRg0KFyxY38f6Fnyb/WnZnIVUKoVczoIQApWKglKKSikAoOuC6oCeJj0ScBX1lM35YBqD42H2PgmiYDvQlKrwX3hJygU8dZBwRDNfwtJ6joPPNtA0sMLTPfOoC7zjtaFp2MKBVi6XcZCBv0FVKJDRpOTXHxZfzv5i/9M1tgx9ZH3nNM90TrK5d5LNfXNovDWLq3c/MJPN+4H+cHWdKjZ/Gnw88YkDY8u8NDjHc92TbOicYlP3a17ofcuL/TM837fExtvLbOx7j/qby7hy7zMzpuWtrI6ChC058SrMhhtTPNE2w5qAFzTNuu5F1vYs8WzPNOu75ljTuchTXQus7ZpnXfcCTnbMseXOCmxZoiaF4Jdwki/ebPHB8w1evh9k60iYgUdhto8EGRhdZfvwN14fCfnsGA0yMBZi68NVto2ssW14FZ4eGP8OWxahmUaMxm6M25E4tyIJWgmD5u4O9e0dRpMO16P7jBg2k7EEo3qOwUjBf5KCmWZaj9HKmIjvGvy9o6PoFKFJ0wDtLMTeHrKZHMr5DJxEBPnIGjJGHNFYHildh52Ow4wbCK/vYDsUAss2ZMZARRaQT8Sgb25yb28fWsV1eSwsnRTpw79HCpMUqWPMoGXloUkpUSoW4UgHjuOg6GsJxynCETZkYR9Cev0SRDYJKQSKpbLvPfI7DoSQKJVK0FzXhfKo/AuBe6grFbguoJTrD/k9v/Y86n9/9bLwByiKBRCuFH84AAAAAElFTkSuQmCC')",
              "backgroundSize": "cover",
              "display": "block"
            }
          }}></span>{`
  `}<img parentName="a" {...{
            "className": "gatsby-resp-image-image",
            "alt": "TuringTrader's Market Vane, Economic Momentum: Cumulative Returns",
            "title": "TuringTrader's Market Vane, Economic Momentum: Cumulative Returns",
            "src": "/static/6cfe937d53d32de7ed089c7b118cd2bc/c1b63/eco-mom-cumulative-returns.png",
            "srcSet": ["/static/6cfe937d53d32de7ed089c7b118cd2bc/5a46d/eco-mom-cumulative-returns.png 300w", "/static/6cfe937d53d32de7ed089c7b118cd2bc/0a47e/eco-mom-cumulative-returns.png 600w", "/static/6cfe937d53d32de7ed089c7b118cd2bc/c1b63/eco-mom-cumulative-returns.png 1200w", "/static/6cfe937d53d32de7ed089c7b118cd2bc/21b4d/eco-mom-cumulative-returns.png 1280w"],
            "sizes": "(max-width: 1200px) 100vw, 1200px",
            "style": {
              "width": "100%",
              "height": "100%",
              "margin": "0",
              "verticalAlign": "middle",
              "position": "absolute",
              "top": "0",
              "left": "0"
            },
            "loading": "lazy",
            "decoding": "async"
          }}></img>{`
  `}</a>{`
    `}</span></p>
    <p>{`Our mechanism for evaluating the unemployment rate uses a state machine with two states. We assume that in a growing economy, unemployment keeps falling. We keep tabs on the lowest recorded unemployment rate and detect a recession when the current unemployment exceeds a threshold based on that lowest level. Similarly, we assume that during a recession, unemployment keeps rising. We track the highest recorded unemployment rate and signal recovery when the current unemployment drops below a threshold based on that maximum level.`}</p>
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "1200px"
        }
      }}>{`
      `}<a parentName="span" {...{
          "className": "gatsby-resp-image-link",
          "href": "/static/5da48ee4c2c0357c43a00de305ad218f/21b4d/eco-mom-rolling-returns.png",
          "style": {
            "display": "block"
          },
          "target": "_blank",
          "rel": "noopener"
        }}>{`
    `}<span parentName="a" {...{
            "className": "gatsby-resp-image-background-image",
            "style": {
              "paddingBottom": "56.333333333333336%",
              "position": "relative",
              "bottom": "0",
              "left": "0",
              "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAALCAYAAAB/Ca1DAAAACXBIWXMAAA7DAAAOwwHHb6hkAAABrUlEQVQoz3VS227TQBT0l/EbfAF/wRNfwSsSLwhxkehDQ9RwjxpUeqE8hKQNihMaFBPHl6zXa+/OoGPHURLakWZ3fHZsn8t6y3CJNPwDpVLEUYThYIzet2uSYI2SO3CqiWPtqRZrS2RZBk9lmp0vQ86DmB9Oprz/8CPvPXjDl2/78AOD08spkuUSg6sZLgYLfP/h4/TsCo8e93hyecOv5xMcHPUxv5kxiiJ4cZziWfsXOj0fh90JnraneP7+N1+/G7H1ecTDrs+Ln3O2umO+OLrmwacx28cTPmn57PSEE7zqjNAfzvh3EcKz5V5J28gCkmYrILrYd6EpO0kSeEVR0DkQqGldTYk5kA7NGVlkirYsqvjG4wDxWGuR5zm8cp1h88Fa1wu2KFhpy8Ji41/vlXDOQSlVZ7hn2Nm3kReWSlua0tEUG0IoZ4swrDLE+kc7kFhpHbSxsNZJWZW+DeKV8oMggJcb6eH/8xCbZNP0VBvH0uLO+QGOVclJqpCuNOJUIUkzJKsMcaIQJQqZzqtGy4WVXah1DmPM5rnR4hHtSTOFMiXhbVpeaGJ3eYSCf0yDTfB09rOiAAAAAElFTkSuQmCC')",
              "backgroundSize": "cover",
              "display": "block"
            }
          }}></span>{`
  `}<img parentName="a" {...{
            "className": "gatsby-resp-image-image",
            "alt": "TuringTrader's Market Vane, Economic Momentum: Rolling Returns",
            "title": "TuringTrader's Market Vane, Economic Momentum: Rolling Returns",
            "src": "/static/5da48ee4c2c0357c43a00de305ad218f/c1b63/eco-mom-rolling-returns.png",
            "srcSet": ["/static/5da48ee4c2c0357c43a00de305ad218f/5a46d/eco-mom-rolling-returns.png 300w", "/static/5da48ee4c2c0357c43a00de305ad218f/0a47e/eco-mom-rolling-returns.png 600w", "/static/5da48ee4c2c0357c43a00de305ad218f/c1b63/eco-mom-rolling-returns.png 1200w", "/static/5da48ee4c2c0357c43a00de305ad218f/21b4d/eco-mom-rolling-returns.png 1280w"],
            "sizes": "(max-width: 1200px) 100vw, 1200px",
            "style": {
              "width": "100%",
              "height": "100%",
              "margin": "0",
              "verticalAlign": "middle",
              "position": "absolute",
              "top": "0",
              "left": "0"
            },
            "loading": "lazy",
            "decoding": "async"
          }}></img>{`
  `}</a>{`
    `}</span></p>
    <p>{`The rolling returns reveal how well this mechanism detected the recessions in 2000 and 2008. However, macro-economic indicators move slowly. As a result, this mechanism cannot adequately react to fast events like the 2020 pandemic: the strategy exits too late and misses part of the recovery.`}</p>
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "1200px"
        }
      }}>{`
      `}<a parentName="span" {...{
          "className": "gatsby-resp-image-link",
          "href": "/static/92c183ce0e6d6b4ae819c86add319276/21b4d/stock-pref-comp.png",
          "style": {
            "display": "block"
          },
          "target": "_blank",
          "rel": "noopener"
        }}>{`
    `}<span parentName="a" {...{
            "className": "gatsby-resp-image-background-image",
            "style": {
              "paddingBottom": "56.333333333333336%",
              "position": "relative",
              "bottom": "0",
              "left": "0",
              "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAALCAYAAAB/Ca1DAAAACXBIWXMAAA7DAAAOwwHHb6hkAAABY0lEQVQoz11T2ZLjMAj0///kzkumdrKO40sHIHVvIceOJlRRQki0G1oeHuOIr68/uN1uCCGglHI6ATQn2MzjWiuv81qbh6S43XcuW8SgqnCrtTbnqxxHPQ6ct/GCJ0Qr92RcgmGLxpwzBjNjby9W6PcdxnUvSuG0KkO246AIxQGdYc/irFqD8iB8JJZQnIm3y/u/hduykRpJCYRElBwo0jG85kVyWoXfj8SfpzAk4zRHas583Ef+/b4zhUjW1wzxbiSL/AZ029PRivNal5Xjz0haIqty3TKT1Is1ukm5WOKAqnplRZTzvJN5p+4zofld8THnPnbVReQQpalrhm2eEZYVVSJMFRWAlXKpb2YwKyexLmfX+JMDPqeF8zQzZ21v7BTi/LqPxPNuLoi753z9fB0u8DCOT8SYkNLhLr2vTj+/4pRii2OM7fGfZznLx72EwbGdfveHtDb6vQ/7zPV3P+t8/x+Z42Cl1s238QAAAABJRU5ErkJggg==')",
              "backgroundSize": "cover",
              "display": "block"
            }
          }}></span>{`
  `}<img parentName="a" {...{
            "className": "gatsby-resp-image-image",
            "alt": "TuringTrader's Market Vane, Stock Preference: Price Comparison",
            "title": "TuringTrader's Market Vane, Stock Preference: Price Comparison",
            "src": "/static/92c183ce0e6d6b4ae819c86add319276/c1b63/stock-pref-comp.png",
            "srcSet": ["/static/92c183ce0e6d6b4ae819c86add319276/5a46d/stock-pref-comp.png 300w", "/static/92c183ce0e6d6b4ae819c86add319276/0a47e/stock-pref-comp.png 600w", "/static/92c183ce0e6d6b4ae819c86add319276/c1b63/stock-pref-comp.png 1200w", "/static/92c183ce0e6d6b4ae819c86add319276/21b4d/stock-pref-comp.png 1280w"],
            "sizes": "(max-width: 1200px) 100vw, 1200px",
            "style": {
              "width": "100%",
              "height": "100%",
              "margin": "0",
              "verticalAlign": "middle",
              "position": "absolute",
              "top": "0",
              "left": "0"
            },
            "loading": "lazy",
            "decoding": "async"
          }}></img>{`
  `}</a>{`
    `}</span></p>
    <h2>{`Stock Market Preference`}</h2>
    <p>{`If we think about combining the stock market indicators and economic momentum, we are on to something. In 2016, Philosophical Economics published a strategy based on this, `}<a parentName="p" {...{
        "href": "http://www.philosophicaleconomics.com/2016/01/gtt/"
      }}>{`Growth Trend Timing`}</a>{`. However, `}<em parentName="p">{`Growth Trend Timing`}</em>{` makes one assumption that we disagree with: It has a built-in bias towards stock-market investments. Instead of this hard-wired bias, we would very much like to have a mechanism to determine this preference dynamically.`}</p>
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "1200px"
        }
      }}>{`
      `}<a parentName="span" {...{
          "className": "gatsby-resp-image-link",
          "href": "/static/c07e578940749b9feec8695c15e1fbb7/21b4d/stock-pref-cumulative-returns.png",
          "style": {
            "display": "block"
          },
          "target": "_blank",
          "rel": "noopener"
        }}>{`
    `}<span parentName="a" {...{
            "className": "gatsby-resp-image-background-image",
            "style": {
              "paddingBottom": "56.333333333333336%",
              "position": "relative",
              "bottom": "0",
              "left": "0",
              "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAALCAYAAAB/Ca1DAAAACXBIWXMAAA7DAAAOwwHHb6hkAAACf0lEQVQoz3VTy0tUYRy9f1Cb2khpiJQa1CJaFNTCFvbQ8VVNBMIQVBs34ouIFrUrWrQpDdRsfMwIYZHOaD4G5zrOjHeu89AZ73Pu/c6Je1UQqh8c+H2/7/wO3wfnSKXSHlRVRalUgmVZcF1B13XhQQhBAdAV3gwEQNIDsa3q/DiT4fsJGS1Pw+x7HYVtW5Bc1+HJAgABUpAU4LECDq+I39sa3nxJ8UYowottY7zQMYGGwCSvh6KoaCakarV6RAZcIXAoK0C3QrJKJa9zeWOXn75t8GH/PC8/mGRz+2c2dU+xKRhhUzCK+u4Ibj5bYKG07wseS/ilVw44Pr3KkQ/L7B2YZUtoivXtX1kbmOGVrlFeC07w0uMoG4NRNj6KsDkYQUPPLG49X2C+uA/Jcd2jzzqUU3m2vpjjqdtTPHMnzNOtYda0TbOuc47nO2Z8nA3M8lxgmnWBadb6mEHN/TCv9s7jQLcgVU2dm1t5vhtP8smrOFv6Yux5ucrOkRX2DC+xa3CRnYOLbB+MMzCw5M86huK81x9jYGiZ3cNx3O2PMfR2DbppQ0pvbnEnleVONs/9YonVvRy31xNUZJmJrE45Z1BWNBaUHBW1zJisMZNW6ZQLzG2nmUwqKO+qTMtpmKYJKZ9OwdzbhXVQhq2VAa2AlZ9x7Kz8QmI9hbRSQSaZQjmXRXEni4UfCWTXlkFHh7yRxEIsA5RzGB37zoxSgnTorf9UJUNq6rH3SL1A6uo/qYblsFDcg2Tohm9o77kevN4wDJimBVPXYGgV6IY3t6EXczB0HZZdhXXE93nenmmiatuQhBC+/06k47B3HAgBLzmw/QS5cP2zx3H/4juO43v5DxL3Acesqp2jAAAAAElFTkSuQmCC')",
              "backgroundSize": "cover",
              "display": "block"
            }
          }}></span>{`
  `}<img parentName="a" {...{
            "className": "gatsby-resp-image-image",
            "alt": "TuringTrader's Market Vane, Stock Preference: Cumulative Returns",
            "title": "TuringTrader's Market Vane, Stock Preference: Cumulative Returns",
            "src": "/static/c07e578940749b9feec8695c15e1fbb7/c1b63/stock-pref-cumulative-returns.png",
            "srcSet": ["/static/c07e578940749b9feec8695c15e1fbb7/5a46d/stock-pref-cumulative-returns.png 300w", "/static/c07e578940749b9feec8695c15e1fbb7/0a47e/stock-pref-cumulative-returns.png 600w", "/static/c07e578940749b9feec8695c15e1fbb7/c1b63/stock-pref-cumulative-returns.png 1200w", "/static/c07e578940749b9feec8695c15e1fbb7/21b4d/stock-pref-cumulative-returns.png 1280w"],
            "sizes": "(max-width: 1200px) 100vw, 1200px",
            "style": {
              "width": "100%",
              "height": "100%",
              "margin": "0",
              "verticalAlign": "middle",
              "position": "absolute",
              "top": "0",
              "left": "0"
            },
            "loading": "lazy",
            "decoding": "async"
          }}></img>{`
  `}</a>{`
    `}</span></p>
    <p>{`We divide the stock market total return index by its bond market counterpart and apply some heavy filtering to determine this preference. Looking at the stock market through this lens gives us a new perspective. Most often, the quotient of prices tops before the S&P 500 reaches its peak, suggesting a much earlier rotation into bonds than many investors realize.`}</p>
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "1200px"
        }
      }}>{`
      `}<a parentName="span" {...{
          "className": "gatsby-resp-image-link",
          "href": "/static/d7007dd471fe98c99ab3075760cc3283/21b4d/stock-pref-rolling-returns.png",
          "style": {
            "display": "block"
          },
          "target": "_blank",
          "rel": "noopener"
        }}>{`
    `}<span parentName="a" {...{
            "className": "gatsby-resp-image-background-image",
            "style": {
              "paddingBottom": "56.333333333333336%",
              "position": "relative",
              "bottom": "0",
              "left": "0",
              "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAALCAYAAAB/Ca1DAAAACXBIWXMAAA7DAAAOwwHHb6hkAAABwElEQVQoz3VR227TQBD1Z/FBfAw/AUK88ICERCVEooqUgpQE5YFS8ZDilginsasEEpNk77vnoF07IQ1wpOOdOTsz65nJfi5XWC0qKLnGer3B16uC1bQE93C8hyB2OsgUlj7GWkgpkTlrmOe3nFU1T95N+OBhB09fXvKunKNYGPQGE5SzBfKbCh+/zHE+vMb7/hUePR5xeDHjOL/D694YdDXrukamlURvVKLb/47B5wpP3hT8cPkDp8OCveGEnX7Bi/GcZ6MpX5xeszuYstsv+Kxb8NXZDZ93cpycT/C2/4235QKZdUctHUIsSOoDwZC090OUjy3jU16zmi+ROecIkCGAAaBvz+jHyGgDjW2lYHA2xftYB80MY050jNFNwaSiCUjJ7ayBP4zYKk9jQ3psn9MuxfvQLuWg4DGPYV2gUI5b6VJhqX3UEBk7SUux1qJ95S/6AGjjEQISkt2Y6T7qzgdo6yO5XC6RbaWhdaDzSHNJZ2ja3ki3v1MmpD/8H4zzFEIii30LqbHZSmyFgpAK643E6peAlDoNWkoBrXWiUgrGmL2/Y6zjnEMWQkCk97E1D7R24wc451PCTtvp/7IjfgP03k6LdsORhAAAAABJRU5ErkJggg==')",
              "backgroundSize": "cover",
              "display": "block"
            }
          }}></span>{`
  `}<img parentName="a" {...{
            "className": "gatsby-resp-image-image",
            "alt": "TuringTrader's Market Vane, Stock Preference: Rolling Returns",
            "title": "TuringTrader's Market Vane, Stock Preference: Rolling Returns",
            "src": "/static/d7007dd471fe98c99ab3075760cc3283/c1b63/stock-pref-rolling-returns.png",
            "srcSet": ["/static/d7007dd471fe98c99ab3075760cc3283/5a46d/stock-pref-rolling-returns.png 300w", "/static/d7007dd471fe98c99ab3075760cc3283/0a47e/stock-pref-rolling-returns.png 600w", "/static/d7007dd471fe98c99ab3075760cc3283/c1b63/stock-pref-rolling-returns.png 1200w", "/static/d7007dd471fe98c99ab3075760cc3283/21b4d/stock-pref-rolling-returns.png 1280w"],
            "sizes": "(max-width: 1200px) 100vw, 1200px",
            "style": {
              "width": "100%",
              "height": "100%",
              "margin": "0",
              "verticalAlign": "middle",
              "position": "absolute",
              "top": "0",
              "left": "0"
            },
            "loading": "lazy",
            "decoding": "async"
          }}></img>{`
  `}</a>{`
    `}</span></p>
    <p>{`Because of these early transitions, the mechanism works surprisingly well - even though it is way too slow to react to quick market changes. The method handled the recessions of 2000 and 2008 very well. However, it cannot track fast events like summer 1998 or March 2020 appropriately because of its slow pace.`}</p>
    <h2>{`VIX Peaks`}</h2>
    <p>{`So far, we have developed three different methods to time the transition between stocks and bonds. A monthly schedule is more than enough for all three, and two of the three mechanisms, the economic momentum, and the stock market preference, actually move even slower than that.`}</p>
    <p>{`It seems that in the past few years, the stock market has increased its pace. In December 2018 and March 2020, we have seen sharp drops followed by quick recoveries, and we should anticipate similar events more often in the future. Momentum-based approaches are not adequate to handle these events, and we need to find different avenues to address this need: adding daily indicators.`}</p>
    <p>{`The `}<a parentName="p" {...{
        "href": "https://en.wikipedia.org/wiki/VIX"
      }}>{`VIX index`}</a>{` is a measure for volatility, as implied through options pricing. As such, the VIX represents the outlook institutional traders have on the S&P 500 in the next 30 days. Typically, the VIX spikes before significant price movements, making it a good measure of market fear.`}</p>
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "1200px"
        }
      }}>{`
      `}<a parentName="span" {...{
          "className": "gatsby-resp-image-link",
          "href": "/static/24270e1bc04d245f4cd7fe105e2d7773/21b4d/vix-peak-cumulative-returns.png",
          "style": {
            "display": "block"
          },
          "target": "_blank",
          "rel": "noopener"
        }}>{`
    `}<span parentName="a" {...{
            "className": "gatsby-resp-image-background-image",
            "style": {
              "paddingBottom": "56.333333333333336%",
              "position": "relative",
              "bottom": "0",
              "left": "0",
              "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAALCAYAAAB/Ca1DAAAACXBIWXMAAA7DAAAOwwHHb6hkAAACc0lEQVQoz3VTS08TYRSdv0Q0GhETCAksjQsXKomJIOVRQBQTI6xUYjRKADXGR1y4M125sYZnKVKIpAJtsaJQCHQodGin0M575hzToUUS9SQn37k3594v+b57hUxWxu7OLrKyDNu2i2TxtCzL1UUAoOOAtu3QNC3AOcwXkT0wMBdL85Uvioych2DbFo8DLsqasB2nGBYzZdKwCf/sNvtehtnYH0JNyyc29E2goBoUTNNEuY9tOyg3LuRyx26xubKWZiq9z49TG2x+EGRdxyir20ZY2xVA9Y0vuHRvntKejGLDw5pSrWUYfOtb4OXeCb7zhen7HGf74znW3wzw6l0/z3f7WdsxxrqeGdbdDrG+ZwY1XdO4cn+eUiYHwSq9k2NZ/P5rl57+ICuujfJUS5Anm0Z5onGEFU2TrGwPsrJ1kme9QZdV3gCr2gM8553C6ZZJXugNIa9oEHRV4eyiyOEPcXYOLbDh4RJbh5bpHV5m9/OIy1svImwdjLDzWZTewUW2DUZ4/ckSPQNRegaW0Pw0wjtv4lBUA8LqSoLJjSRT2xLlvQwL0jYzmwmuxlc5Hc1yJpblRDjNzXWRX6M7jG4oTCRSVLNpSskks9IeNta2KIkiDF2HICbWYeQkWGoepnIAMydBEdewFf2Giz1+nPGM4fn7OSjSNh69DmE8GIf4IwboeeRSSRT297H+M4GD5Crz+TyEP9/xNyLhZY4HYkdxOinSzIj/cYOynIOgKgp0XYemaS5drapQNQ2WrsDWCygoGjTdgC7vQlMV6IYJ/bhf06CqKgzDgOA4jju8pS2Bc6Qtdy4ty3aL3O1xUPLY//Db7iz/BoxCBIrCxKkxAAAAAElFTkSuQmCC')",
              "backgroundSize": "cover",
              "display": "block"
            }
          }}></span>{`
  `}<img parentName="a" {...{
            "className": "gatsby-resp-image-image",
            "alt": "TuringTrader's Market Vane, VIX Peaks: Cumlative Returns",
            "title": "TuringTrader's Market Vane, VIX Peaks: Cumlative Returns",
            "src": "/static/24270e1bc04d245f4cd7fe105e2d7773/c1b63/vix-peak-cumulative-returns.png",
            "srcSet": ["/static/24270e1bc04d245f4cd7fe105e2d7773/5a46d/vix-peak-cumulative-returns.png 300w", "/static/24270e1bc04d245f4cd7fe105e2d7773/0a47e/vix-peak-cumulative-returns.png 600w", "/static/24270e1bc04d245f4cd7fe105e2d7773/c1b63/vix-peak-cumulative-returns.png 1200w", "/static/24270e1bc04d245f4cd7fe105e2d7773/21b4d/vix-peak-cumulative-returns.png 1280w"],
            "sizes": "(max-width: 1200px) 100vw, 1200px",
            "style": {
              "width": "100%",
              "height": "100%",
              "margin": "0",
              "verticalAlign": "middle",
              "position": "absolute",
              "top": "0",
              "left": "0"
            },
            "loading": "lazy",
            "decoding": "async"
          }}></img>{`
  `}</a>{`
    `}</span></p>
    <p>{`We are opposed to the idea of measuring the VIX in absolute levels. In the past 30 years, volatility has changed a lot, and we believe our mechanisms need to adapt to that. Our method of evaluating the VIX measures the current reading relative to its 3-months average. In doing so, we focus more on the VIX spiking than on its absolute value. As seen in the 2020 pandemic, if volatility remains elevated, this cancels the trigger once the average has caught up.`}</p>
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "1200px"
        }
      }}>{`
      `}<a parentName="span" {...{
          "className": "gatsby-resp-image-link",
          "href": "/static/e84704fc80e8a237a4cf2d2cc6ea7631/21b4d/vix-peak-rolling-returns.png",
          "style": {
            "display": "block"
          },
          "target": "_blank",
          "rel": "noopener"
        }}>{`
    `}<span parentName="a" {...{
            "className": "gatsby-resp-image-background-image",
            "style": {
              "paddingBottom": "56.333333333333336%",
              "position": "relative",
              "bottom": "0",
              "left": "0",
              "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAALCAYAAAB/Ca1DAAAACXBIWXMAAA7DAAAOwwHHb6hkAAABxUlEQVQoz31S227TQBD1x/EXfAPPSHwCT7zywuUBKiokpEYRhFAorVKqIJEIEgmqEidNQiGt3NbX9Xr3HDTr2EkjwUjjc8Zzdjw+thcEl7i8OEOWRlBZhh/fJ5xP5iBJY0mTK1ZhjOXI/0NdGKlAOpm7hLFCFCfwrqOUW40BF+chd/ZGvHWnjdv33nG3cyJCfOyO0RvM0B/OkKQKdx900Nwd4v6jzzzqz9kbztH94mO7+Y3+dAEvCmO8aPtof5rgoPcLT1tTPmtP+er9iA9fDvi4cczW4Skb+z6fvz7mVusnP3SnfPLmlK3OmG8PJ9jZ87Hf9fl7cQGvKAr+K5rtrzwZn9f1UX/GJEo2ZaheO4oieFprWoDWwqGxZQpf985KaRVhCkpL9IBLAaq8QJqmqw3d3VJQ19Whuk+yMGBeWIfaoYXokkxDKSUbrgb+LyXkoMqNDHEpA3VhkeUG8pDrqyu34dIDYM2PjSx7ubaQjTb7xsJtGQRBuaH8b/IKxqDGG3zpq9KWa9be/DIAnYdhlCBOM8RJijjJkNQ8rXkYxa4nPMsy5Hnu/JKsuAzTWsOzVky1MMbAWrPipuSCckjQLLPSbHKJv8tnSdDG5OmYAAAAAElFTkSuQmCC')",
              "backgroundSize": "cover",
              "display": "block"
            }
          }}></span>{`
  `}<img parentName="a" {...{
            "className": "gatsby-resp-image-image",
            "alt": "TuringTrader's Market Vane, VIX Peaks: Rolling Returns",
            "title": "TuringTrader's Market Vane, VIX Peaks: Rolling Returns",
            "src": "/static/e84704fc80e8a237a4cf2d2cc6ea7631/c1b63/vix-peak-rolling-returns.png",
            "srcSet": ["/static/e84704fc80e8a237a4cf2d2cc6ea7631/5a46d/vix-peak-rolling-returns.png 300w", "/static/e84704fc80e8a237a4cf2d2cc6ea7631/0a47e/vix-peak-rolling-returns.png 600w", "/static/e84704fc80e8a237a4cf2d2cc6ea7631/c1b63/vix-peak-rolling-returns.png 1200w", "/static/e84704fc80e8a237a4cf2d2cc6ea7631/21b4d/vix-peak-rolling-returns.png 1280w"],
            "sizes": "(max-width: 1200px) 100vw, 1200px",
            "style": {
              "width": "100%",
              "height": "100%",
              "margin": "0",
              "verticalAlign": "middle",
              "position": "absolute",
              "top": "0",
              "left": "0"
            },
            "loading": "lazy",
            "decoding": "async"
          }}></img>{`
  `}</a>{`
    `}</span></p>
    <p>{`As the charts show, the mechanism has worked quite well for the recessions of 2008 and 2020. For the recession in 2000, it did not do much, as at that time, prices declined at a slow pace. In September 2015 and February 2018, we experienced some false triggers. However, these did not harm the performance of the strategy too severely. Overall, we feel that this mechanism is an excellent addition to our set of indicators.`}</p>
    <h2>{`Sharp Declines`}</h2>
    <p>{`With the mechanisms in place so far, we have done quite a bit to protect our portfolio. For slow-moving recessions, our momentum indicators should trigger. For faster events, the VIX-mechanism should step in. However, it is conceivable that our method of determining VIX peaks fails, and we'd like to have a fail-safe.`}</p>
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "1200px"
        }
      }}>{`
      `}<a parentName="span" {...{
          "className": "gatsby-resp-image-link",
          "href": "/static/c71f5c7cc119e69e5d7327788ef15a36/21b4d/stop-loss-cumulative-returns.png",
          "style": {
            "display": "block"
          },
          "target": "_blank",
          "rel": "noopener"
        }}>{`
    `}<span parentName="a" {...{
            "className": "gatsby-resp-image-background-image",
            "style": {
              "paddingBottom": "56.333333333333336%",
              "position": "relative",
              "bottom": "0",
              "left": "0",
              "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAALCAYAAAB/Ca1DAAAACXBIWXMAAA7DAAAOwwHHb6hkAAACdUlEQVQoz31T30vTURz9/kX9gqCaSFIkRA++9JBBD0pGbtaMoVFBkU9FgmWmRIS9VIRPBWHGbFs6Spml++qWbZM1N9vcd5vb9/f33s+J71VBiDpw4HwO5x4ul8+VlEqVisUiKUqFLMsizjkYY2TbjtAuiAicu+TY9pnw3VlOV+jV+wRGX87TVl0jiTEHe0Hu6V0NEONi3ku4zttIFr0PZtDWN02erkmcvfmZ6qpJkm3btNNDjPHtMm7TWqYAcEuMlq4jGstiNbWB8FwWvffDaPF+RJM3jJPXZui4f5baB+ZRVmqicPc2AoaqYujFHE54P2Dw+Rc8m1jEhbtRNPdE0H59Emf8k2juCeNUIIrWwCxaAzPU4o/Q+YF5bLqFjG2/B7MtLMh5nLsVwv7OII55IzjQOY19HUEcuhiCxxvGkcshHO2OoMkXhscbgqc75Go6fOkT2m5EqaGZJBmaiphcwOOJJHqHF9E5GIf/SQJXR1bQPxZH31gc/WMy/CPLCIzKCIws4cqwjO6hOHyPZPgeLpFvWMbt8R+k6RZJieUUfucKUEoK9K0qDGUDlVwGuVQGX5M1xFZrmI2XsZZex2KyhIV0A/n1Epx6GY3SBsx6lbKZHPLZdbItk6RfqQyZ1RLZWl1QLRdpM7NK6W8xOu17Rwc7pqjrzhStJjJ072mUXr/9TpupFWJ6g7RSnmytQSvxn5RLJqGqDZLwHwSDCxh/Mwfs7J1SKIBqhX/mK9UaSZqmkWmaZBiGoNC6ToZhkmNq5Biq8HTDJKtWJEPXyLRsMvfmDYM0TRcfQ+KcE3PJmCDf1Y4j9tJhXBxyPYfTTob9nWdM7PIff0YB7PqQshoAAAAASUVORK5CYII=')",
              "backgroundSize": "cover",
              "display": "block"
            }
          }}></span>{`
  `}<img parentName="a" {...{
            "className": "gatsby-resp-image-image",
            "alt": "TuringTrader's Market Vane, Sharp Declines",
            "title": "TuringTrader's Market Vane, Sharp Declines",
            "src": "/static/c71f5c7cc119e69e5d7327788ef15a36/c1b63/stop-loss-cumulative-returns.png",
            "srcSet": ["/static/c71f5c7cc119e69e5d7327788ef15a36/5a46d/stop-loss-cumulative-returns.png 300w", "/static/c71f5c7cc119e69e5d7327788ef15a36/0a47e/stop-loss-cumulative-returns.png 600w", "/static/c71f5c7cc119e69e5d7327788ef15a36/c1b63/stop-loss-cumulative-returns.png 1200w", "/static/c71f5c7cc119e69e5d7327788ef15a36/21b4d/stop-loss-cumulative-returns.png 1280w"],
            "sizes": "(max-width: 1200px) 100vw, 1200px",
            "style": {
              "width": "100%",
              "height": "100%",
              "margin": "0",
              "verticalAlign": "middle",
              "position": "absolute",
              "top": "0",
              "left": "0"
            },
            "loading": "lazy",
            "decoding": "async"
          }}></img>{`
  `}</a>{`
    `}</span></p>
    <p>{`To create this fail-safe, we evaluate rolling drawdowns over the past month. Should our losses over this relatively short period exceed a threshold, we exit the stock market. This mechanism is similar to Charles LeBeau's `}<em parentName="p">{`Chandelier Stop`}</em>{`.`}</p>
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "1200px"
        }
      }}>{`
      `}<a parentName="span" {...{
          "className": "gatsby-resp-image-link",
          "href": "/static/c682514943ae7e57ee5a9cb5b29ad631/21b4d/stop-loss-rolling-returns.png",
          "style": {
            "display": "block"
          },
          "target": "_blank",
          "rel": "noopener"
        }}>{`
    `}<span parentName="a" {...{
            "className": "gatsby-resp-image-background-image",
            "style": {
              "paddingBottom": "56.333333333333336%",
              "position": "relative",
              "bottom": "0",
              "left": "0",
              "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAALCAYAAAB/Ca1DAAAACXBIWXMAAA7DAAAOwwHHb6hkAAAByUlEQVQoz31S207bQBT0F/Y7+gN97kv/oFJfW1ShSqBSKoRCihBUVZQ8AIlQmgfUlCQkhKA6JrG9u97LTLVrnKYXeqSxzxyPZ885dhTHCeZ3U8h8icUixc1owtN2H+O7nD6cVqzCWsfFPHlgBiR8Ei6EhZQSUZ5Lvj/4ytvZgjtHfT55doynL064Xet5IVrtETrdMdrdMe6XEhu7F/hY7+L5qxYb52O22tdonn5HrzfkdBYjStMM20cDHDaHaHSm2Khfc+t4gt2TK758d8E3e5f81Bxx/8uAb/cvuVn7xq3DPl/XhjxoDFhvDLH3+Qqt8yFvZz8QGWP4r3AO3PxwxsnNfFVrdsbM0uxPKaqx03RZGjogGPi7dSU8X99doChIZ1bPjXVU2qLkFkopRFrr8hjgL/iXqjx0IAyVDiYstKMsLLVxyJVlKnRp+NjIq3nWDIWyzKQJna1Lgqk0TJIEUaG1bxnGugCfO5TcBSdUO1qr+TN+RbXDOI4RZaKgsfgNvhM/WpJqysLROZb1whJ4fBIhBCIpBIqigFIShVJhD/fLPPykuZDIcoFlmiHL5ape6kttlYsHn8g5B2vtCuu8yr3Qf0X3H42Hj58ilkmd9avw7wAAAABJRU5ErkJggg==')",
              "backgroundSize": "cover",
              "display": "block"
            }
          }}></span>{`
  `}<img parentName="a" {...{
            "className": "gatsby-resp-image-image",
            "alt": "TuringTrader's Market Vane, Sharp Declines",
            "title": "TuringTrader's Market Vane, Sharp Declines",
            "src": "/static/c682514943ae7e57ee5a9cb5b29ad631/c1b63/stop-loss-rolling-returns.png",
            "srcSet": ["/static/c682514943ae7e57ee5a9cb5b29ad631/5a46d/stop-loss-rolling-returns.png 300w", "/static/c682514943ae7e57ee5a9cb5b29ad631/0a47e/stop-loss-rolling-returns.png 600w", "/static/c682514943ae7e57ee5a9cb5b29ad631/c1b63/stop-loss-rolling-returns.png 1200w", "/static/c682514943ae7e57ee5a9cb5b29ad631/21b4d/stop-loss-rolling-returns.png 1280w"],
            "sizes": "(max-width: 1200px) 100vw, 1200px",
            "style": {
              "width": "100%",
              "height": "100%",
              "margin": "0",
              "verticalAlign": "middle",
              "position": "absolute",
              "top": "0",
              "left": "0"
            },
            "loading": "lazy",
            "decoding": "async"
          }}></img>{`
  `}</a>{`
    `}</span></p>
    <p>{`For any mechanism we introduce, our first premise should be similar to that of a doctor: `}<em parentName="p">{`Do no harm`}</em>{`. As the charts show, the method is not stellar. However, it has proven useful during the 2008 and 2020 recessions, quite similar to the VIX Peaks mechanism. With that, we believe it to be a helpful addition.`}</p>
    <h2>{`Putting Everything Together`}</h2>
    <Aside mdxType="Aside">
      <p><strong parentName="p">{`See Also`}</strong></p>
      <ul>
        <li parentName="ul"><a parentName="li" {...{
            "href": "/portfolios/tt-market-vane/"
          }}>{`Market Vane portfolio`}</a></li>
      </ul>
    </Aside>
    <p>{`Wow, we have collected quite a few mechanisms here! How can we combine these in a meaningful way? We decided on the following scheme:`}</p>
    <ul>
      <li parentName="ul">{`On the last day of every month, we perform a simple majority vote on the monthly indicators. If the two or more indicators signal bullish conditions, the result is bullish, otherwise bearish.`}</li>
      <li parentName="ul">{`Unless any of the two daily indicators signal a high-risk situation, we plan to use this majority vote for the following month. Should a daily indicator signal bearish conditions, this overrides the majority vote for the next month.`}</li>
      <li parentName="ul">{`Every day, we recheck the daily indicators. If any of these two indicators signal a bearish condition, this overrides the majority vote.`}</li>
    </ul>
    <p>{`Most often, this scheme leads to trades on a monthly schedule. In the case of high-volatility events, we might exit our stocks in favor of bonds before month-end. However, transitions from bonds back into stocks always occur at the month-end.`}</p>
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "1200px"
        }
      }}>{`
      `}<a parentName="span" {...{
          "className": "gatsby-resp-image-link",
          "href": "/static/55b84eeb9b63020276f9a656b940c12b/21b4d/market-vane-cumulative-returns.png",
          "style": {
            "display": "block"
          },
          "target": "_blank",
          "rel": "noopener"
        }}>{`
    `}<span parentName="a" {...{
            "className": "gatsby-resp-image-background-image",
            "style": {
              "paddingBottom": "56.333333333333336%",
              "position": "relative",
              "bottom": "0",
              "left": "0",
              "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAALCAYAAAB/Ca1DAAAACXBIWXMAAA7DAAAOwwHHb6hkAAACYUlEQVQoz3VTS08TYRSdn6UJGB+JCrLSDRo1hQWJPG3AsCExEVHLRl0Y3bnWnQsSlKahQDAaoDx8IG1py0wfQ9+dzqMz35xjZgqBYDzJyX3k3pMvN+eTKpUqVFVFqVSG4zgQQlAI4UW4rksAdD26oO0IAi5I0rYdpvMa13fLePkxzsl326g1dEhCODwDf+FIxMdRz6fZEpz/pmLo1Ra7Jr/y8sNlnBsIs3cqiqZuQrJt2x88hnDRVjyFSsPmZqLGt59SvDezzvMPFnlxOMLuYJg94xF0B8Pon15msVT1BX0B1z0lI0ymU3lu/Mzx6fsd3nm8whvBMK8OzrF77AtvPlpgz8Qiu8aXeG1iBRfHlnF7eq0t6N3sGK5j8/NKkkOhVV4aXWTnwBw7BubZMRhh58gSr48t8MpohB3DUb/uHI7ywkgU3otvTa2iabQomYbBlFJl5LvC1x9+s/fJGntnthiY3WZ/aIN9oQ0/BkKbDIRi7Huxzv7ZTd5/HmMgtMW+UAx3n8UYfPMDTd2CVFRkphIHjCdyzGRUHipZZv7Emd5LMpkzmMobjMsalUyO8XSZu7LBvUSOVbVAeT9DRVahpBVmMwpalgVJP1QgGiXYegPC1GBVi1CTe6jv76BSKKBe11HKKjBKeRQVBfkDGcXEL9BuopaXodXq0A+zaOaS1DQNknBd/hdVmWyo3nXbtV4itcKJt864rVKtQTIMA5ZlwTRNn35uGDBNC6behNHUoBtevwW9XICh67BaNqzT86YJT6fVakFyXRfC48nvaOeOA8/YQrj+kt/za29G/DsvhO/jv4ycCc7CVSuTAAAAAElFTkSuQmCC')",
              "backgroundSize": "cover",
              "display": "block"
            }
          }}></span>{`
  `}<img parentName="a" {...{
            "className": "gatsby-resp-image-image",
            "alt": "TuringTrader's Market Vane: Cumulative Returns",
            "title": "TuringTrader's Market Vane: Cumulative Returns",
            "src": "/static/55b84eeb9b63020276f9a656b940c12b/c1b63/market-vane-cumulative-returns.png",
            "srcSet": ["/static/55b84eeb9b63020276f9a656b940c12b/5a46d/market-vane-cumulative-returns.png 300w", "/static/55b84eeb9b63020276f9a656b940c12b/0a47e/market-vane-cumulative-returns.png 600w", "/static/55b84eeb9b63020276f9a656b940c12b/c1b63/market-vane-cumulative-returns.png 1200w", "/static/55b84eeb9b63020276f9a656b940c12b/21b4d/market-vane-cumulative-returns.png 1280w"],
            "sizes": "(max-width: 1200px) 100vw, 1200px",
            "style": {
              "width": "100%",
              "height": "100%",
              "margin": "0",
              "verticalAlign": "middle",
              "position": "absolute",
              "top": "0",
              "left": "0"
            },
            "loading": "lazy",
            "decoding": "async"
          }}></img>{`
  `}</a>{`
    `}</span></p>
    <p>{`What sticks out here is that we did not need to apply any additional magic here. We combined the five strategies using the scheme outlined above, and each method contributes to the overall outcome. For each of the five components, we can make a case why we want to include it, and we have been careful to avoid curve-fitting. In extending our backtest to span more than 30 years, we have further increased our confidence in the indicator.`}</p>
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "1200px"
        }
      }}>{`
      `}<a parentName="span" {...{
          "className": "gatsby-resp-image-link",
          "href": "/static/281b8b4c07cb17469c927d486c9130a8/21b4d/market-vane-rolling-returns.png",
          "style": {
            "display": "block"
          },
          "target": "_blank",
          "rel": "noopener"
        }}>{`
    `}<span parentName="a" {...{
            "className": "gatsby-resp-image-background-image",
            "style": {
              "paddingBottom": "56.333333333333336%",
              "position": "relative",
              "bottom": "0",
              "left": "0",
              "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAALCAYAAAB/Ca1DAAAACXBIWXMAAA7DAAAOwwHHb6hkAAABuElEQVQoz3VS204TURSdD/RzfNTwE33wyQcT7wlvoNZaNAQQaBpjxASBodpCUVvazuXc5py1zJ4LqYGuZOess2ftffZlotlshvn0CovFHEppxKe/aBcTsEaeKS4DJmUIgWQBspTVWg+tNaI00zz6PqRViusfznjv/hYetva4sfUDl9cOa48OcXwyxk5vgHefL9B62sdaaxsPWnvsH43Z+zrCm4/H6H+JeXk1QXQ9S7G5M0Tv2xjdwws8aY/4rDPkejdmZ/ecr97H7B6MuLE94PO3J3zdifmifcbHmz/Z3h2IHy/bp/i0f87ffyaIvC+4EuovSbvkEH5Lj6btNE0ROecIoLQQQF+b8ADWPJS80DlD4Vh40FjPwgeJg2iMcVC5QlQU1YuSkHXiatS4eaj8JgUbT209rQv0HuVpXECmPZPMVkuRCpuEy8HLvIFxntr4//yoLuXG5Y+RClH7b0H8hQ/wPiAEQFvfzOtO+XQ6RWStW7kTYwNtEco5Snsy11WQhHmeI0rSHEmmsZAzVRVPcsyTHGmmYYyBUqo8xWRO1tqbe8NF45xDFIK0I2350u7iEtD4VmnEBP8AKWZLaoTzNG8AAAAASUVORK5CYII=')",
              "backgroundSize": "cover",
              "display": "block"
            }
          }}></span>{`
  `}<img parentName="a" {...{
            "className": "gatsby-resp-image-image",
            "alt": "TuringTrader's Market Vane: Rolling Returns",
            "title": "TuringTrader's Market Vane: Rolling Returns",
            "src": "/static/281b8b4c07cb17469c927d486c9130a8/c1b63/market-vane-rolling-returns.png",
            "srcSet": ["/static/281b8b4c07cb17469c927d486c9130a8/5a46d/market-vane-rolling-returns.png 300w", "/static/281b8b4c07cb17469c927d486c9130a8/0a47e/market-vane-rolling-returns.png 600w", "/static/281b8b4c07cb17469c927d486c9130a8/c1b63/market-vane-rolling-returns.png 1200w", "/static/281b8b4c07cb17469c927d486c9130a8/21b4d/market-vane-rolling-returns.png 1280w"],
            "sizes": "(max-width: 1200px) 100vw, 1200px",
            "style": {
              "width": "100%",
              "height": "100%",
              "margin": "0",
              "verticalAlign": "middle",
              "position": "absolute",
              "top": "0",
              "left": "0"
            },
            "loading": "lazy",
            "decoding": "async"
          }}></img>{`
  `}</a>{`
    `}</span></p>
    <p>{`As a first test, we use this indicator to switch between two ETFs: SPY tracking the S&P 500 index and IEF to represent 10-Year U.S. Treasuries. As the charts show, `}<em parentName="p">{`Market Vane`}</em>{` has done remarkably well. Outside of recessions, we mostly track the performance of the S&P 500. With every downturn, this simple strategy made additional gains over the S&P 500. The strategy handled slow-moving declines and sharp drops equally well, resulting in a smooth equity curve and low drawdowns throughout the whole simulation period.`}</p>
    <p>{`With these properties, `}<em parentName="p">{`TuringTrader`}</em>{`'s `}<em parentName="p">{`Market Vane`}</em>{` is a powerful tool to guide your investment decisions. At `}<em parentName="p">{`TuringTrader.com`}</em>{`, we aim to make your investments a smoother ride. That's why we provide the indicator for free to all interested investors and with no signup required.`}</p>
    <PortfolioPerformanceChart mdxType="PortfolioPerformanceChart" />
    <h2>{`Intended Use`}</h2>
    <p>{`There are many uses for `}<em parentName="p">{`Market Vane`}</em>{`'s regime indicator. From a 30,000ft perspective, the indicator's purpose is to guide switching between bull and bear market strategies or risk-on and risk-off portfolio configurations. `}<em parentName="p">{`Market Vane`}</em>{` is designed to work with bull market strategies focused on U.S. stocks and bear market strategies built around bonds and other asset classes with low or inverse correlations to U.S. stocks.`}</p>
    <p>{`In its simplest form, investors can use the indicator to switch between just two ETFs: One tracking the S&P 500 and another tracking 10-Year U.S. Treasuries. And while this is an overly simplistic strategy, it very well demonstrates Market Vane's value.`}</p>
    <ButtonPrimary text="check out market vane portfolio" to="/portfolios/tt-market-vane/" mdxType="ButtonPrimary" />
    <br /><br /><br />

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      